import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { sr } from '../../utils';
import { result } from 'underscore';

export const Payments = () => {
  const permissions = useSelector(store => store.appMain.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [bankId, setBankId] = useState(1);
  const [data, setData] = useState(null);
  const [totalSum, setTotalSum] = useState(0);
  const [paymentsToTbankResponse, setPaymentsToTbankResponse] = useState(null);

  useEffect(() => {
    console.log(paymentsToTbankResponse);
    if (paymentsToTbankResponse && paymentsToTbankResponse.length > 0) {
      const updatedData = data.map(item => {
        const payment = paymentsToTbankResponse.find(payment => payment.kitchenId === item.IdKitchen);
        if (payment) {
          item.bankStatus = payment.status;
          item.bankMessage = payment.message;
        }
        return item;
      });
console.log(updatedData)
      setData(updatedData);
    }
  }, [paymentsToTbankResponse]);

  const checkRole = (param) => {
    if (permissions.staff) {
        if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
            return true
        } else {
            return permissions.permissions.includes(param)
        }
    } else {
        return false
    }
  }

  if(!checkRole('screen_payments')) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>Нет прав!</div>;
  }

  const handleGetData = async () => {
    setIsLoading(true);
    await sr('Reports', 'GetPaymentsFromGoogleSheet', {})
      .then(response => {
        console.log(response);
        if (response.result) {
          const responseData = response.data.payments || [];
          setData(responseData);
          const sum = responseData.reduce((acc, item) => acc + parseFloat(item.SumToPay), 0);
          setTotalSum(sum);
          setIsLoading(false);
        }
      })
      .catch(error => {
          console.log(error);
          setIsLoading(false);
      })
  }

  const renderJson = (data) => {
    if (typeof data === 'object' && data !== null) {
      return (
        <ul style={{width: 'fit-content'}}>
          {Object.keys(data).map((key) => (
            <li style={{textAlign: 'left'}} key={key}>
              <strong>{key}:</strong> {renderJson(data[key])}
            </li>
          ))}
        </ul>
      );
    } else {
      return <span>{String(data)}</span>;
    }
  };

  const handlePushPaymentsToTbank = async () => {
    await sr('Reports', 'pushPaymentsToTbank', { data })
      .then(response => {
        if (response.data.result && response.data.result.length > 0) {
          setPaymentsToTbankResponse(response.data.result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetPaymentsTxt = async () => {
    await sr('Reports', 'getPaymentsTxt', { data, bankId })
      .then(response => {
        if (response.result === 1 && response.data.status === 'ok') {
          const link = document.createElement('a');
          link.href = response.data.link;
          link.download = 'payments.txt';
          link.click();
        } else {
          console.log('Ошибка при получении файла');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Wrapper>
      <Header>
        <Button onClick={handleGetData}>Загрузить данные из таблицы</Button>
      </Header>

      <Content>
        {isLoading ? (
          <>Загрузка...</>
        ) : (
            <>
            {data && data.length > 0 ? (
              <>
                <Table>
                  <thead>
                    <tr>
                      {paymentsToTbankResponse && paymentsToTbankResponse.length > 0 &&
                        <th style={{maxWidth: '150px'}}>Статус</th>
                      }
                      <th>ID кухни</th>
                      <th>Название кухни</th>
                      <th>Сумма к оплате</th>
                      <th>Период</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        {paymentsToTbankResponse && paymentsToTbankResponse.length > 0 &&
                          <td style={{maxWidth: '150px'}}>
                            <p style={{color: item.bankStatus === 200 ? 'green' : 'red'}}>{item.bankStatus === 200 ? 'OK' : 'NOK'}</p>
                            <p style={{fontSize: '12px'}}>{item.bankMessage}</p>
                          </td>
                        }
                        <td>{item.IdKitchen}</td>
                        <td>{item.NameKitchen}</td>
                        <td>{item.SumToPay}</td>
                        <td>{item.PeriodDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Total>
                  Итого: {data.length} платежей на сумму {totalSum}р
                </Total>
                <Button onClick={() => {
                  if (window.confirm('Вы уверены, что хотите отправить черновики в Т-Банк?')) {
                    handlePushPaymentsToTbank();
                  }
                }}>Отправить черновики в Т-Банк</Button>
                <Button onClick={handleGetPaymentsTxt}>Скачать платежки в TXT</Button>
                <CustomSelect
                  value={bankId}
                  onChange={(e) => setBankId(e.target.value)}
                >
                  <CustomOption value={1}>Альфа Банк</CustomOption>
                  <CustomOption value={2}>Т Банк</CustomOption>
                </CustomSelect>

                {paymentsToTbankResponse &&
                  <div style={{margin: '20px 0'}}>
                    {renderJson(paymentsToTbankResponse)}
                  </div>
                }
              </>
            ) : (
                <>Нет данных</>
            )}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 16px;
`;

const Content = styled.div`

`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }
`;

const Total = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #6c757d;
`;

const CustomSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const CustomOption = styled.option`
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  margin-top: auto;
  padding: 8px 16px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;


export default Payments;
